/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
import { navigate } from "gatsby"

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (process.env.NODE_ENV) {
    if (location.pathname === "/fr" || location.pathname === "/fr/")
      return navigate("/")
  }
}

export const onClientEntry = () => {
  if (process.env.NODE_ENV === "development") {
    const checkTitlePresence = () => {
      const buttonElement = document.querySelector("button") // Sélectionnez l'élément titre
      if (
        buttonElement &&
        buttonElement.textContent.includes("Preview custom 404 page")
      ) {
        buttonElement.click()
      }
    }

    // Surveillez les changements dans le DOM pour réagir aux modifications dynamiques
    const observer = new MutationObserver(checkTitlePresence)
    observer.observe(document, { childList: true, subtree: true })

    return () => {
      observer.disconnect() // Nettoyez l'observateur lors de la fermeture de l'application
    }
  }
}
