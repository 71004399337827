exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-404-jsx": () => import("./../../../src/pages/en/404.jsx" /* webpackChunkName: "component---src-pages-en-404-jsx" */),
  "component---src-pages-en-jsx": () => import("./../../../src/pages/en.jsx" /* webpackChunkName: "component---src-pages-en-jsx" */),
  "component---src-pages-en-search-jsx": () => import("./../../../src/pages/en/search.jsx" /* webpackChunkName: "component---src-pages-en-search-jsx" */),
  "component---src-pages-fr-404-jsx": () => import("./../../../src/pages/fr/404.jsx" /* webpackChunkName: "component---src-pages-fr-404-jsx" */),
  "component---src-pages-fr-recherche-jsx": () => import("./../../../src/pages/fr/recherche.jsx" /* webpackChunkName: "component---src-pages-fr-recherche-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

